<template>
  <div class="w1200">
    <div class="moneyread">
      <h3>NOTICE</h3>
        <ul>
          <li><!--img src="@/assets/img/icon_check.png" /-->1. {{$t('front.cash.moneyCheckListN')}}</li>
          <li><!--img src="@/assets/img/icon_check.png" /-->2. {{$t('front.cash.moneyCheckListB')}}</li>
          <li><!--img src="@/assets/img/icon_check.png" /-->3. {{$t('front.cash.moneyCheckListD')}}</li>
        </ul>
    </div>

      <div class="moneyinfoappli">
        <div class="moneyinfo">
          <h3 class="applih">{{$t('front.cash.moneyOutInput')}}</h3>
          <p class="applip b0 mb0 pb0">{{$t('front.cash.moneyCheckListO')}}</p>
        </div>

        <div class="appliinputwrap">
          <ul class="appliinput">
            <li>{{$t('front.cash.haveCash')}}</li>
            <li><span class="w260">{{thousand(userData.cashAmt)}}</span>{{$t('front.common.money')}}</li>
          </ul>
          <!--ul class="appliinput">
            <li>{{$t('front.cash.haveGameCash')}}</li>
            <li><span class="or w110">{{thousand(coinAmt ? coinAmt.toString(): '0')}}</span>{{$t('front.common.money')}}<a class="applibtn" @click="moneyChange">{{$t('front.cash.cashChange')}}</a></li>
          </ul-->
          <ul class="appliinput">
            <li>{{$t('front.cash.moneyOutInput')}}</li>
            <li><input type="text" class="numb w260" pattern="[0-9.,]+" :placeholder="$t('front.cash.inputNumber')" v-model="getCurrentChargeMoney" />{{$t('front.common.money')}}</li>
          </ul>
          <ul class="moneybtnwrap">
            <li class="one"><a @click="setMoney(10000)">1{{$t('front.cash.manwon')}}</a></li>
            <li class="one"><a @click="setMoney(30000)">3{{$t('front.cash.manwon')}}</a></li>
            <li class="one"><a @click="setMoney(50000)">5{{$t('front.cash.manwon')}}</a></li>
            <li class="two"><a @click="setMoney(100000)">10{{$t('front.cash.manwon')}}</a></li>
            <li class="two"><a @click="setMoney(300000)">30{{$t('front.cash.manwon')}}</a></li>
            <li class="two"><a @click="setMoney(500000)">50{{$t('front.cash.manwon')}}</a></li>
            <li class="two"><a @click="setMoney(1000000)">100{{$t('front.cash.manwon')}}</a></li>
            <li class="thr"><a @click="setMoney(0)">{{$t('front.cash.correct')}}</a></li>
          </ul>
          <ul class="appliinput">
            <li>{{$t('front.cash.withdrawPassword')}}</li>
            <li class="bbl"><input type="password" class="numb" :placeholder="$t('front.cash.inputPassword')" v-model="cashOutPass"></li>
          </ul>
          <div class="applibtns">
            <a @click="onClickTM()">{{$t('front.cash.moneyCheckListL')}}</a>
          </div>
        </div>
      </div>

      <div class="board moneyreadWrap">
        <ul class="boardw first">
          <li class="">{{$t('front.board.applyDay')}}</li>
          <li class="">{{$t('front.board.withdrawMoney')}}</li>
          <li class="">{{$t('front.board.processDay')}}</li>
          <li class="">{{$t('front.board.processState')}}</li>
        </ul>
        <template v-if="cashList.length > 0">
          <ul class="boardw" v-for="item in cashList" v-bind:key="item.cashIdx">
            <li class="">{{dateFormat(item.regDate)}}</li>
            <li class="">{{thousand(item.cashAmt)}}</li>
            <li class="">{{dateFormat(item.updDate)}}</li>
            <li class=""><span :class="{'bl' : item.cashStatus !== '-1'}">{{computedCashStatus(item.cashStatus).text}}</span></li>
          </ul>
        </template>
        <template v-else>
          <ul class="boardw">
            <li>{{$t('front.common.notFoundList')}}</li>
          </ul>
        </template>
        <div class="boardpage mt20">
          <pagination :pageNum="pageInfo.page"
                      :pageSize="pageInfo.count_per_list"
                      :totalCount="pageInfo.tatal_list_count"
                      @goToPage="onCashListSearch" />
        </div>
      </div>
    </div>

    <transition name="fade">
      <TMmodal v-show="TMmodal" :isOpen="TMmodal" :type="'header'" @close="onSubmit" @cancel="onCloseTM"/>
    </transition>

<!--  <div class="loginmodal" v-if="TMmodal && state !== 'ready'">-->
<!--    <div class="modal-content agreeart art">-->
<!--      <span class="img"></span>-->
<!--      <p id="mainTM" class="modaltxt">-->
<!--        <span v-if="state === 'ready'">게임 완전 종료 처리중입니다.<br> 잠시만(1분이내) 기다려주세요.</span>-->
<!--        <span v-if="state === 'complete'">환전을 위한 유효성 검사가 완료되었습니다.<br> 환전신청을 눌러주세요.</span>-->
<!--        <span v-if="state === 'fail'">통합머니로 전환 실패. 고객센터로 문의주세요</span>-->
<!--      </p>-->
<!--      <button class="yes" @click="onSubmit" v-if="state === 'complete'">환전 신청하기</button>-->
<!--      <button class="closeBtn" @click="onCloseTM">X</button>-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
import Pagination from '@/components/ui/Pagination'

import { cashDelete, cashOut, gameMoneyToCash } from '@/api/cash'
import store from '@/store'
import { getDateStr, getSubDaysDate, isMobile, thousand } from '@/libs/utils'

// import { setMoneyMerge, setMoneyMergeForPartner } from '@/api/member'
import TMmodal from '@/components/common/TotalMoney.vue'

export default {
  name: 'Exchange',
  components: {
    TMmodal,
    Pagination
  },
  watch: {
    type: function () {
      // if (this.type === 'list') {
      this.onCashListSearch()
      // }
    },
    getCurrentChargeMoney () {
      // this.setCurrentChargeMoney = thousand(this.setCurrentChargeMoney)
      const parts = this.getCurrentChargeMoney.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
      // use this for numeric calculations
      console.log('number for calculations: ', calcNum)
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      console.log(n)
      this.getCurrentChargeMoney = n
    }
  },
  created () {
    this.onCashListSearch()
  },
  data () {
    return {
      type: 'apply',
      getCurrentChargeMoney: '0',
      cashOutPass: '',
      allChecked: false,
      cashList: [],
      cashType: 'out',
      searchDate: {
        startDate: getDateStr(getSubDaysDate(new Date(), 2), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(new Date(), 'yyyy-MM-dd 23:59:59')
      },
      TMmodal: false,
      state: 'ready'
    }
  },
  methods: {
    onClickTM () {
      document.body.style.overflow = 'hidden'
      if (!this.userData.outAmtYn || this.userData.outAmtYn === 'N') {
        this.onCheck('출금 정지된 상태입니다.')
        return false
      }
      if (!this.cashOutPass) {
        this.onCheck('출금 비밀번호를 입력해주세요.')
        return false
      }
      if (this.getCurrentChargeMoney && this.getCurrentChargeMoney !== '0') {
        this.TMmodal = true
      } else {
        this.onCheck('front.cash.emptyPrice')
      }
    },
    onCloseTM () {
      this.TMmodal = false
    },
    async moneyChange () {
      if (this.coinAmt && this.coinAmt !== '0') {
        const confirm = await this.onConfirm('front.cash.confirmGameMoneyToCash')
        if (confirm) {
          const params = {
            cashAmt: this.coinAmt
          }
          gameMoneyToCash(params).then(response => {
            const result = response.data
            if (result.resultCode === '0') {
              store.dispatch('storeUserCoinAmt')
              store.dispatch('storeUserData')
              // this.userData.coinAmt = data.balance
              this.onCheck('front.cash.pointToCashComplete')
            }
          })
        }
      } else {
        this.onCheck('front.cash.emptyGamePoint')
      }
    },
    async onRemoveMsg () {
      let checkedCount = 0
      this.cashList.forEach(item => {
        if (item.checked) {
          checkedCount++
        }
      })
      if (checkedCount <= 0) {
        this.onCheck('front.cash.noSelectMessage')
        return false
      }
      const confirm = await this.onConfirm('front.cash.confirmDeleteMessage')

      if (confirm) {
        for (let i = 0, iLen = this.cashList.length; i < iLen; i++) {
          const item = this.cashList[i]
          if (item.checked) {
            cashDelete(item).then(response => {
            })
          }
        }
        this.onCheck('front.cash.completeDeleteMessage')
        this.onCashListSearch()
      }
    },
    setMoney (value) {
      let money = Number(this.getCurrentChargeMoney.replace(/,/g, ''))
      if (value) {
        if (money + value <= this.userData.cashAmt) {
          money += value
        } else {
          this.onCheck('환전 금액이 현재 보유캐시보다 큽니다.')
        }
      } else {
        money = value
      }

      this.getCurrentChargeMoney = thousand(money)
    },
    async onSubmit (device = 'mobile') {
      document.body.style.overflow = 'auto'
      this.TMmodal = false
      if (!this.userData.outAmtYn || this.userData.outAmtYn === 'N') {
        this.onCheck('출금 정지된 상태입니다.')
        return false
      }
      if (!this.cashOutPass) {
        this.onCheck('출금 비밀번호를 입력해주세요.')
        return false
      }
      if (this.getCurrentChargeMoney && this.getCurrentChargeMoney !== '0') {
        const confirm = await this.onConfirm('front.cash.confirmExchange')

        if (confirm) {
          const params = {
            memId: this.userData.memId,
            cashType: -1, // in,
            cashStatus: 'out',
            cashAmt: this.getCurrentChargeMoney.replace(/,/g, ''),
            cashOutPass: this.cashOutPass
          }

          cashOut(params).then(response => {
            store.dispatch('storeUserData').then(userData => {

            }).then(async responseInfo => {
              const result = response.data
              if (result.resultCode === '0') {
                const confirm = await this.onCheck('front.cash.completeExchange')
                if (confirm) {
                  if (isMobile()) {
                    this.replacePageByName('my')
                  } else {
                    location.reload()
                  }
                }
              } else if (result.resultCode === 'C099') {
                await this.onAlert('warningart', result.resultMessage)
              } else {
                await this.onCheck('api.' + result.resultCode)
              }
            })
          }).catch(err => {
            console.error(err)
            this.onCheck('출금 비밀번호를 확인해주세요.')
          })
        }
      } else {
        this.onCheck('front.cash.emptyPrice')
      }
      this.TMmodal = false
    }
  }
}
</script>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
